.keyboard__clz.simple-keyboard {
    max-width: 975px;
}

.keyboard__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
}

.keyboard__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 68px !important;
    justify-content: center;
    padding: 10px;
    font-size: 35px !important;
}

.enter-key__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white;
}

.keyboard-full-screen__clz.simple-keyboard {
    max-width: 100%;
}

.keyboard-full-screen__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
}

.keyboard-full-screen__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 75px !important;
    justify-content: center;
    font-size: 35px !important;
}
