.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms;
}

/* slide enter */
.slide-enter {
    opacity: 0;
    transform: scale(0.6) translateY(100%);
}

.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.slide-exit {
    opacity: 1;
    transform: translateY(0);
}

.slide-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

/* rotate enter */
.rotate-enter {
    transform: rotateY(90deg) rotateX(45deg);
}
.rotate-enter-active {
    transform: rotateY(0deg) rotateX(0deg);
    transition: transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.rotate-exit {
    transform: rotateY(0deg) rotateX(0deg);
}
.rotate-exit-active {
    transform: rotateY(-50deg) rotateX(-20deg);
}

/* left to right */
.left-to-right-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.left-to-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.left-to-right-exit {
    opacity: 1;
    transform: translateX(0);
}

.left-to-right-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}

/* right to left*/
.right-to-left-enter {
    opacity: 0;
    transform: translateX(100%);
}

.right-to-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.right-to-left-exit {
    opacity: 1;
    transform: translateX(0);
}

.right-to-left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

/* top to bottom */
.top-to-bottom-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.top-to-bottom-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.top-to-bottom-exit {
    opacity: 1;
    transform: translateY(0);
}
.top-to-bottom-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 300ms, transform 300ms;
}
