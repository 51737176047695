.payment-with-instruction_clz{
flex-direction: column ;
align-items: center;
 justify-content: center
}

.payment-without-instruction_clz{
align-items: start;
 justify-content: center
}

.payment-with-partial-instruction_clz{
flex-direction: column ;
align-items: center;
}