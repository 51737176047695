.prm-cart-close-button {
    top: -51px;
    left: 39%;
    cursor: pointer;
    width: 240px;
    height: 51px;
    position: relative;
    font: normal normal 300 15px/18px Segoe UI;
    color: #000000;
    background: url(../../Icons/png_icons/arrow-icon-close.png);
}

.prm-cart-products-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    overflow-y: auto;
    padding-top: 98px;
    scrollbar-width: none;
}

.prm-header-popup {
    position: absolute;
    z-index: 1000;
    width: 1080px;
    background: white;
    top: 0;
    padding-top: 15px;
}

.prm-title-popup-cart {
    font: normal normal 600 58px/70px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
    height: 58px;
    margin-top: "unset";
}

.prm-number-product-in-cart {
    text-align: center;
    font: normal normal normal 28px/34px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 20px;
    height: 28px;
    margin-bottom: 15px;
}
