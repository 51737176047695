.workflow-display {
    z-index: 99999 !important;
    position: fixed !important;
    bottom: 0 !important;
}

.dialog__clz .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: transparent !important;
}

.prm-keyboard-comment__clz.simple-keyboard {
    max-width: 90%;
}

.space-key__clz {
    width: 50% !important;
}
