.prm-numeric-keyboard-wrapper__clz {
    min-height: 350px;
    max-width: 350px;
    border: 1px solid #00000029;
    font-size: 30px;
    border-radius: 23px;
    padding: 0px 60px 0px 60px;
    box-shadow: 0px 3px 6px #00000029;
}

.numeric-keyboard-wrapper__clz {
    min-height: 650px;
    max-width: 600px;
    margin-bottom: 50px;
    border: 1px solid #00000029;
    font-size: 30px;
    border-radius: 23px;
    padding: 0px 90px 0px 90px;
    box-shadow: 0px 3px 6px #00000029;
}

.numeric-keyboard-wrapper__clz > input {
    width: 250px;
    font-size: 35px;
    border: unset;
    border-bottom: 1px solid #00000029;
}

.numeric-keyboard__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
}

.numeric-keyboard__clz.hg-theme-default.hg-layout-numeric .hg-button {
    font: normal normal bold 23px/27px Arial;
    align-items: center;
    display: flex !important;
    height: 100px !important;
    justify-content: center !important;
    width: 100px !important;
}

.numeric-keyboard__clz.hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 20px;
}

.numeric-keyboard__clz.hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 20px;
}

.numeric-keyboard__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000052;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 5px;
}
.numeric-keyboard__clz.hg-theme-default .hg-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.hg-button[disabled] {
    pointer-events: none;
    opacity: 0.5;
}
