.hidden-promo-code__clz {
    font-size: 35px;
    border: unset;
    outline: none;
    cursor: default;
}

.hidden-promo-code__clz:focus {
    outline: none;
    caret-color: transparent;
}

.promotion-prm-image__clz {
    height: 70px;
    width: 70px;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 15px;
}
