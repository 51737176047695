.logo-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}
.content-alert-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 38%;
    font-size: 45px;
    line-height: 1.2em;
}
.footer-alert-cart {
    display: flex;
    justify-content: flex-end;
    height: 230px;
}
.text-footer-alert-cart {
    display: flex;
    align-items: flex-end;
    text-align: center;
    font: 600 45px / 60px Segoe UI;
    text-decoration: underline;
    line-height: 1.5em;
}
