:root {
    --height-workflow-header: 435px;

    --swiper-width: 0px;
    --progress-color: "";
}

.workflow-header {
    /* width: 1040px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
    /* background: transparent
        linear-gradient(180deg, #ffffff 0%, var(--global-color) 200%) 0% 0%
        no-repeat padding-box; */
    box-shadow: 0px 3px 5px #00000029;
    opacity: 1;
}
.workflow-title {
    gap: 15px;
}

.workflow-product-title {
    height: 60px;
    text-align: left;
    font: normal normal bold 30px/60px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
}

.workflow-tree {
    width: 1030px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.workflow-footer {
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.workflow-basket {
    width: 100%;
    height: 245px;
    background: #eaeaea 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
}

.workflow-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.drop-item-button {
    width: 266px;
    height: 65px;
    border: 1px solid #05fee1;
    border-radius: 38px;
}

.drop-item-button-text {
    text-align: center;
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #6a92ff;
}

.workflow-total {
    text-align: center;
    font: normal normal 600 29px/38px Segoe UI;
    letter-spacing: 0px;
    color: #1f1f1f;
    width: max-content;
}

.workflow-timeline {
    /* margin-top: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.workflow-main-step-title {
    /* width: 44px; */
    /* height: 33px; 50*/
    /* text-align: center; */
    font: normal normal 600 16px/33px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    /* text-transform: lowercase; */
    text-align: center;
}

.workflow-main-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-tree-step-title {
    /* width: 44px; */
    /* height: 33px; 50*/
    /* text-align: center; */
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-align: center;
}

.workflow-tree-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step-title {
    /* width: 44px; */
    /* height: 33px; 50*/
    /* text-align: center; */
    font: normal normal 600 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-transform: lowercase;
    text-align: center;
}

.workflow-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step {
    background: #ededed 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    height: 40px;
}

.workflow-main-content {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* width: 860px; */
}

.workflow-swiper-slide {
    width: 270px !important;
    /* padding: 50px 0px 50px 0px !important; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    position: relative;
    height: 100%;
    /* gap: 15px; */
}

.workflow-swiper-container .swiper-wrapper {
    height: 300px !important;
    padding-top: 0.25rem !important;
}

.workflow-swiper-container-items-fewer-than-four .swiper-wrapper {
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, 0px) !important;
    justify-content: center;
}

.workflow-swiper-container.swiper {
    height: 300px !important;
    width: var(--swiper-width) !important;
    margin-left: unset !important;
    display: flex;
    align-items: center;
    margin-right: unset !important;
}

.step-item {
    border-radius: 50%;
    /*  border: 3px solid rgb(15, 15, 15); */
    background-color: white;
    padding: 10px 13px;
    color: #00000052;
    transition: border-color color;
    /* transition-duration: 0.7s; */
    text-align: center;
    width: 60px;
    height: 59px;
    outline: 3px solid var(--progress-color);
    outline-offset: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 25px/60px Segoe UI;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    position: relative; /* absolute*/
    padding: unset !important;
}

.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
}

.workflow-swiper-slide::after {
    content: "";
    width: 232px;
    height: 15px;
    background-color: white;
    position: absolute;
    top: 220px;
    left: 161px;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    z-index: -1;
}

.workflow-swiper-slide:last-child::after {
    content: "";
    background-color: transparent;
    width: 0px;
}

.active-line {
    width: 106px;
    height: 47px;
    border-bottom: 3px solid var(--progress-color) !important;
    position: absolute;
    top: 182px;
    left: 159px;
    z-index: 1;
    animation: lineActiveTrans 0s linear;
}

.completed-line {
    width: 223px !important;
    height: 47px;
    border-bottom: 3px solid var(--progress-color) !important;
    position: absolute;
    top: 182px;
    left: 160px;
    z-index: 2;
    animation: lineCompletedTrans 0s normal forwards;
}

@keyframes lineActiveTrans {
    0% {
        width: 0px;
        /*transform: translateX(-50%);*/
    }
    50% {
        width: 70px;
    }
    100% {
        width: 106px;
        /* transform: translateX(50%); */
    }
}

@keyframes lineCompletedTrans {
    0% {
        width: 106px;
        /*transform: translateX(-50%);*/
    }

    50% {
        width: 180px;
    }

    100% {
        width: 225px;
        /* transform: translateX(50%); */
    }
}

.outer {
    background-color: #0000001c;
    height: 80px;
    width: 80px;
    position: relative;
    z-index: -3;
    top: 8px;
    left: 8px;
    /*   animation: waves 1.5s infinite ease-in-out; */
}

@keyframes waves {
    /* 0% {
        transform: scale(1);
        opacity: 1;
    }
    /* 50% {
        background-color: red;
    }  
    100% {
        transform: scale(1.2);
        opacity: 0; 
    } */
    to {
        box-shadow: 0 0 0 3px #0000000d, 0 0 0 6px #00000005, 0 0 0 10px #0000,
            0 0 0 6px #00000024;
    }
}

.inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--progress-color);
    height: 50px;
    width: 50px;
    position: relative;
    border-radius: 50%;
    /* z-index: 1;
    top: 8px;
    left: 8px; */
}

.more-outer {
    background-color: transparent;
    height: 100px;
    width: 100px;
    border: 2px solid #0000001c;
    position: relative;
    z-index: 0;
    /* animation: waves 1.5s infinite ease-in-out; */
}

.circle {
    border-radius: 50%;
}

.h-110 {
    height: 110px;
}

.non-active-step {
    outline: unset;
    z-index: 2;
}

.top-button-position {
    position: relative;
    top: 85px;
}

.step-image {
    gap: 15px;
    height: 144px;
    /* width: 130px; */
    /* padding-right: 20px; */
}

.w-1068 {
    width: 1068px;
}

.arrow-right,
.arrow-left {
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 5px solid gray;
    border-left: 5px solid gray;
}

.arrow-right {
    transform: rotate(135deg);
    right: 20px;
}

.arrow-left {
    transform: rotate(-45deg);
    left: 20px;
    /* margin-right: 20px; */
}

.shopcart-products-swiper-container .swiper-wrapper {
    height: 100% !important;
    padding-top: 0.25rem !important;
}

.shopcart-products-swiper-container.swiper {
    height: 100% !important;
    width: 1030px !important;
    margin-left: unset !important;
    display: flex;
    width: 100%;
    margin-right: unset !important;
}

.shopcart-products-swiper-container
    > .swiper-pagination
    > .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: var(--global-color) !important;
}

.swiper-pagination-bullet__clz > .swiper-pagination {
    bottom: 0px !important;
    left: 0;
    width: 100%;
}

.workflow-vertical-separation-line {
    height: 45px;
    width: 2px;
    background-color: #cccccc;
}
.disable-edit {
    pointer-events: none;
    opacity: 0.5;
}
.maher:before {
    background-size: 100% 100%;

    width: 100%;
    height: 100%;
    content: url("https://www.shutterstock.com/image-photo/healthy-food-clean-eating-selection-260nw-722718097.jpg");
    background-image: url("https://www.shutterstock.com/image-photo/healthy-food-clean-eating-selection-260nw-722718097.jpg");
}

@keyframes tada {
    0% {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.tada {
    animation-name: tada;
    animation-duration: 1s;
}
.shopcart-product-animation {
    animation-name: fadeInDownSlideElement;
    animation-duration: 1.5s;
}
