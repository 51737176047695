.yes-button {
    width: 257px;
    height: 59px;
    background: transparent linear-gradient(180deg, #fb1a05 0%, #bf1000 100%) 0%
        0% no-repeat padding-box;
    border: 1px solid #ff0000;
    border-radius: 38px;
}

.no-button {
    width: 257px;
    height: 59px;
    background: #ea5c5c 0% 0% no-repeat padding-box;
    border: 1px solid #fe0505;
    border-radius: 38px;
}

.yes-button-text {
    text-align: center;
    font: normal normal bold 30px/41px Nunito;
    letter-spacing: 0px;
    color: #ffffff;
}

.no-button-text {
    text-align: center;
    font: normal normal 600 27px/36px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
}

.image-product__clz {
    height: 84px;
    width: 112px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.see-details-button {
    width: 140px;
    height: 50px;
    background: #ea5c5c 0% 0% no-repeat padding-box;
    border: 1px solid #fe0505;
    border-radius: 10px;
    border: none;
}

.product-info-container__clz {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.image-product__clz {
    height: 160px;
    width: 160px;
    margin-left: 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.composed-product__clz {
    position: relative;
    margin: 0 auto;
    margin-bottom: 44px;
    width: 982px;
    min-height: 300px;
    /* UI Properties */
    background: #fdfdfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border: 1px solid #f8f8f6;
    opacity: 1;
    border-radius: 15px;
}
