.Toastify__toast-container--bottom-right {
    bottom: 5%;
}

.Toastify__close-button > svg {
    margin-top: 0.875rem !important;
    height: 1.25rem !important;
    width: 1.2rem !important;
}

.Toastify__toast-theme--dark {
    border-style: solid !important;
}
