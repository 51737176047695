.prm-kiosk-languages-swiper-container__clz.swiper {
    width: 70%;
    align-items: center;
    gap: 20px;
}

.prm-kiosk-languages-swiper-container__clz .swiper-wrapper {
    margin-left: 10px !important;
}

.prm-kiosk-languages-swiper-container__clz .swiper-button-prev {
    color: black !important;
}

.prm-kiosk-languages-swiper-container__clz .swiper-button-prev::after {
    font-size: 20px !important;
}

.prm-kiosk-languages-swiper-container__clz .swiper-button-next {
    color: black !important;
}

.prm-kiosk-languages-swiper-container__clz .swiper-button-next::after {
    font-size: 20px !important;
}
