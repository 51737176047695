.primary-btn-hover__clz:hover {
    opacity: 1 !important;
    color: #000000 !important;
}

.MuiSvgIcon-fontSizeMedium {
    font-size: 3rem !important;
}

.MuiCircularProgress-colorPrimary {
    color: white !important;
}

.MuiStepLabel-labelContainer {
    margin-top: 1.5rem;
    max-width: 8rem;
    margin-inline: auto;
}

.lapel-step-regular {
    text-align: center;
    max-inline-size: 1rem;
    font: normal normal normal 15px/20px Segoe UI;
}
