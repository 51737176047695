@keyframes tada {
    0% {
        transform: scale(1);
    }
    10%,
    20% {
        transform: scale(0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale(1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        transform: scale(1.1) rotate(-3deg);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.ReactModal__Content__clz {
    animation: tada 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ReactModal__Content__clz .before-close {
    animation: fadeOut 0.5s ease-in-out;
}
