.category__clz {
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}

.category-card__clz {
    width: auto;
    height: auto;
    border-radius: 9px;
    border: none;
    background-color: transparent;
    /* opacity: 0.8 !important; */
    max-height: 100%;
    max-width: 100%;
    /* box-shadow: 0px 3px 6px #00000029; */
}

.category-swiper-slide-test__clz {
    height: 230px !important;
    /* width: 190px !important;
    margin-left: 10px;
    text-align: center;
    background-color: #e9e9e9;
    white-space: nowrap;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.category-swiper__clz.swiper {
    width: 190px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    height: 100% !important ;
}
.category-swiper__clz.swiper-wrapper {
    gap: 5px !important;
}

.active-category-card__clz {
    height: auto !important;
    /* min-height: var(--max-height) !important; */
    border-radius: 9px;
    border-bottom: var(--global-color) 5px solid !important;
    position: relative;
    border: none;
    background-color: transparent;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}

.active-category-card__clz::after {
    content: "";
    background: transparent
        linear-gradient(180deg, #ffffff00 50%, var(--global-color) 100%)
        no-repeat;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--global-color) 5px solid;
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    opacity: 0.3;
}

.category-title__clz {
    font: normal normal bold 13px/21px Segoe UI;
    text-align: center;
    width: 166px;
    letter-spacing: 0px;
    color: #343434;
    line-height: 16px;

    /* text-transform: uppercase; */
}

.active-category-title__clz {
    font: normal normal bold 13px/21px Segoe UI;
    text-align: center;
    width: 190px;
    letter-spacing: 0px;
    color: #343434;
    line-height: 16px;
    /* text-transform: uppercase; */
}

.category-swiper-slide__clz {
    height: var(--max-height) !important;
    min-height: var(--max-height) !important;
    margin-top: 0.1rem;
}

/* .category-swiper-slide__clz { */
/* transform: translateY(0); */
/* animation-name: fadeInSlideDown;
    animation-duration: 3s;  */
/*  height: 200px !important;*/
/* } */

@keyframes fadeInSlideDown {
    0% {
        opacity: 0.5;
    }
    /* 50% {
        opacity: 0.7;
    } 
    100% {
        opacity: 1;
    }*/
}

.category-swiper__clz {
    /* margin-top: 0.9rem; */
    /* transform: translateY(0); */
}

@keyframes fadeInDown {
    0% {
        z-index: -10;
        transform: translateY(-55%);
    }
    20% {
        transform: translateY(-60%);
    }
    90% {
        transform: translateY(5%);
    }
    100% {
        z-index: 0;
        transform: translateY(0);
    }
}
.category-image {
    height: auto !important;

    width: 202px;
    max-width: 100%;
    display: block !important;
    background-color: transparent;
}
