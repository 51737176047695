.menu-buttons__clz {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    background: white;
    height: 52px;
    font-family: "Segoe UI";
    font-size: 15px;
    font-weight: bold;
    color: "#000000";
    text-transform: uppercase;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000000;
    border-radius: 12px;
    cursor: pointer;
}
