@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.tech-problem-modal__clz .modal-content {
    border: 1px solid #707070;
    border-radius: 30px;
    height: 897px;
}

.tech-problem-btn__clz {
    font: normal normal bold 16px/19px Segoe UI;
    letter-spacing: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
}
