.composed-product {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    width: 90%;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 15px;
    margin-bottom: 20px;
    justify-content: center;

    background: #fdfdfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border: 1px solid #f8f8f6;
    opacity: 1;
}

.composed-product-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.product-info__clz {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}
.composed-product-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 185px;
    width: 98%;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 15px;
}
.composed-product-comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 185px;
    width: 98%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: var(--global-color) 1px solid;
    border-radius: 15px;
}
.basic-composition {
    font: normal normal normal 17px/22px Segoe UI;
    color: #5d5d5d;
}
.header-composed-product {
    display: flex;
    flex-direction: column;
    /* max-height: 175px; */
    width: 100%;
    font: normal normal bold 35px/47px Nunito;
}
.composed-product-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 20%;
    font: normal normal bold 35px/47px Nunito;
}

.centered-items {
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-element {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /* margin-left: 20%; */
    font: normal normal bold 35px/47px Nunito;
}
.composed-product-characteristic {
    font: normal normal normal 18px/24px Segoe UI;
    color: #5d5d5d;
}

.column-centered-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-composed-product {
    height: 100px;
    width: 133px;
    margin-left: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.see-details {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 53px;
    background: transparent 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    background-color: var(--global-color);
}

.recap-price-product {
    font: normal normal 600 35px/42px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    text-transform: uppercase;
    opacity: 1;
}

.recap-product-title {
    display: flex;
    flex-direction: column;
    font: normal normal 600 30px/48px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    gap: 20px;
    align-items: flex-start;
    height: 100%;
    width: 540px;
}

.recap-product-operations {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.recap-image-product {
    height: 155px;
    width: 116px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.title-product {
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    min-width: max-content;
}
.number-composed-product {
    text-align: center;
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    display: inline-block;
    margin-right: 15px;
    width: 100px;
}
.preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
}

.summary-options-product {
    font: normal normal 30px/24px Segoe UI;
    color: #b7b7b7;
    text-align: left;
}
