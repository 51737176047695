.kiosk-languages-swiper-container__clz.swiper {
    width: 90%;
    display: flex;
    align-items: center;
}

.kiosk-languages-swiper-container__clz .swiper-wrapper {
    margin-left: 60px !important;
}

.kiosk-languages-swiper-container__clz .swiper-button-prev {
    color: black !important;
}

.kiosk-languages-swiper-container__clz .swiper-button-prev::after {
    font-size: 30px !important;
}

.kiosk-languages-swiper-container__clz .swiper-button-next {
    color: black !important;
}

.kiosk-languages-swiper-container__clz .swiper-button-next::after {
    font-size: 30px !important;
}
.main-content-scroll {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: gray #e0e0e0;
}
