.usual-mode-logo__clz {
    height: 230px;
    width: auto;
}

.prm-mode-logo__clz {
    z-index: 1;
    height: 190px;
    width: auto;
}
.email-prm-mode-logo__clz {
    z-index: 1;
    height: 160px;
    width: auto;
}
