.standby-screen-swiper-wrapper__clz.swiper {
    height: 100% !important;
}

.local-settings-button__clz {
    height: 45px !important;
    width: 45px !important;
}

.local-settings-button__clz:hover {
    border: none !important;
    background: none !important;
}
