.prm-numeric-keyboard__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue,
        Helvetica, Arial, Lucida Grande, sans-serif;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
}

.prm-numeric-keyboard__clz.hg-theme-default.hg-layout-numeric .hg-button {
    font: normal normal bold 23px/27px Arial;
    align-items: center;
    display: flex !important;
    height: 50px !important;
    justify-content: center !important;
    width: 50px !important;
}

.prm-numeric-keyboard__clz.hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 15px;
}

.prm-numeric-keyboard__clz.hg-theme-default
    .hg-row
    .hg-button:not(:last-child) {
    margin-right: 10px;
}

.prm-numeric-keyboard__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000052;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 5px;
}

.hg-button[disabled] {
    pointer-events: none;
    opacity: 0.5;
}
